<template>
    <div class="pb-3 text-muted text-center">Version: {{ version }}-{{ endpoint }}</div>
</template>

<script>
import info from "../../../../package.json";
import { firebaseConfig } from "../../../firebase";

const ENDPOINT_CAPTIONS = {
    "fishyapp-dev": "d",
    "fishyapp-prod": "p"
};

export default {
    name: "Version",
    computed: {
        version() {
            return info.version;
        },
        endpoint() {
            return ENDPOINT_CAPTIONS[firebaseConfig.projectId] || firebaseConfig.projectId;
        }
    }
};
</script>
