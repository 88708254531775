module.exports = {
    baseUrl: "https://fishyapp-dev.firebaseapp.com",
    mapUrl: "https://map-1632c.kxcdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png",
    storageUrl: "https://storage.googleapis.com",
    firebaseConfig: {
        apiKey: "AIzaSyD_Ba7-bjjEKd3mBcXFrsvws_MPIsW-MK0",
        authDomain: "fishyapp-dev.firebaseapp.com",
        databaseURL: "https://fishyapp-dev.firebaseio.com",
        projectId: "fishyapp-dev",
        storageBucket: "fishyapp-dev.appspot.com",
        messagingSenderId: "197686724460",
        appId: "1:197686724460:web:d20d71bc066f861e8f9549",
        measurementId: "G-PS1YD62QRY"
    }
};
